import React, { useState, useEffect } from 'react'
import WorkoutDetail from '../components/live/workoutDetail'
import { ILiveWorkout } from '../types'
import LayoutNormal from '../templates/Layout'

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../constants/colors'
import { signupLiveWorkout } from '../util/fetch/workouts'
import Snackbar from '@material-ui/core/Snackbar'
import styled from 'styled-components'
import { PHONE } from '../constants/measurements'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchLiveWorkoutDetail } from '../util/fetch/workouts'
import { withErrorHandler } from '../components/errorHandler'

interface IProps {
  location: {
    search: string
    state: {
      handleMainButtonClick: (
        workout: ILiveWorkout,
        userSignedUp: boolean,
      ) => void
      workoutDetailData: ILiveWorkout
    }
  }
}

export const Wrap = styled.div`
  margin-top: 20px;
  padding: 60px;
  @media (max-width: ${PHONE}) {
    margin-top: 0;
    padding: 15px;
  }
`

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const WorkoutDetailPage = (props: IProps): React.ReactElement => {
  const [snackbarMessage, setSnackbarMessage] = useState('Workout deleted')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [workoutDetailData, setWorkoutDetailData] = useState(null)
  const [loading, setLoading] = useState(true)

  const { search } = props.location
  const id = search.split('?id=').pop()

  const getWorkoutDetail = async () => {
    const response = await fetchLiveWorkoutDetail(Number(id))
    setWorkoutDetailData(response)
    setLoading(false)
  }

  useEffect(() => {
    if (search !== '') {
      getWorkoutDetail()
    }
  }, [])

  const handlePurchase = (workout: ILiveWorkout) => {
    signupLiveWorkout(workout.id as number).then(() => {
      setSnackbarMessage('You signed up for a workout')
      setOpenSnackbar(true)
      setLoading(true)
      getWorkoutDetail()
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <LayoutNormal>
        <Wrap>
          <LoaderWrap>{loading && <CircularProgress />}</LoaderWrap>

          <WorkoutDetail
            workoutDetailData={workoutDetailData}
            handleButtonClick={handlePurchase}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            message={snackbarMessage}
            autoHideDuration={3000}
          />
        </Wrap>
      </LayoutNormal>
    </ThemeProvider>
  )
}

export default withErrorHandler(WorkoutDetailPage)
